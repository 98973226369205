import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { UserApiService } from "@quipex/shared/data";
import { filter, map } from "rxjs";

export const maintenanceGuard = () => {
    const userService = inject(UserApiService);
    const router = inject(Router);

    return userService.userMe$
        .pipe(
            filter((currentUser) => currentUser !== null),
            map((currentUser) => {
                if (currentUser) {
                    const isMaintenanceContractor = currentUser.role?.toLowerCase().indexOf('maintenance') > -1;
                    const isUnitOwner = currentUser.role?.toLowerCase().indexOf('unit owner read') > -1;
                    const isBuilder = currentUser.role?.toLowerCase().indexOf('builder') > -1;

                    if (isMaintenanceContractor || isUnitOwner || isBuilder) {
                        router.navigateByUrl('/buildings');
                        return false;
                    }
                    return true;
                }
                return false;
            })
        );
}